<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-text-field
        v-model="mfg"
        label="MFG"
        required
        :rules="[requiredRule]"
      ></v-text-field>
      <v-text-field
        v-model="nvt"
        label="NVT"
        required
        type="number"
        :rules="[numericRule]"
      ></v-text-field>
      <v-text-field
        v-model="nvtSpliceCassette"
        label="NVT-Spleißkasette"
        required
        :rules="[requiredRule]"
      ></v-text-field>
      <v-text-field
        v-model="pipeUnion"
        label="Rohrverband"
        required
        type="number"
        :rules="[numericRule]"
      ></v-text-field>
      <v-select
        v-model="numberOfFibers"
        label="Faseranzahl"
        :items="[4, 6, 12]"
        required
      ></v-select>
      <v-autocomplete
        v-model="singleTubeColor"
        :items="availableTubeColors"
        item-text="description"
        item-value="´name"
        key-name="name"
        :rules="[requiredRule]"
        label="Einzelrohr (IEC 60304)"
      >
        <template v-slot:selection="{ item }">
          <div class="color-indicator">
            <div
              class="left-indicator"
              :style="{ background: item.color }"
            ></div>
            <div
              v-if="item.number <= 12"
              class="middle-indicator"
              :style="{ background: item.color }"
            ></div>
            <div
              v-else-if="item.number > 12"
              class="middle-indicator"
              style="background: #000"
            ></div>
            <div
              class="right-indicator"
              :style="{ background: item.color }"
            ></div>
          </div>
          {{ item.number }} - <b class="ml-2"> {{ item.description }}</b>
          <i class="ml-2" style="font-size: 12px"> ({{ item.name }})</i>
        </template>
        <template v-slot:item="{ item }">
          <div class="color-indicator">
            <div
              class="left-indicator"
              :style="{ background: item.color }"
            ></div>
            <div
              v-if="item.number <= 12"
              class="middle-indicator"
              :style="{ background: item.color }"
            ></div>
            <div
              v-else-if="item.number > 12"
              class="middle-indicator"
              style="background: #000"
            ></div>
            <div
              class="right-indicator"
              :style="{ background: item.color }"
            ></div>
          </div>
          {{ item.number }} - <b class="ml-2"> {{ item.description }}</b>
          <i class="ml-2" style="font-size: 12px"> ({{ item.name }})</i>
        </template>
      </v-autocomplete>
      <v-checkbox
        v-model="otdrProtocol"
        label="OTDR-Protokoll vorhanden"
        :rules="[requiredRule]"
      ></v-checkbox>
      <v-text-field
        v-model="inspectionReport"
        label="Begehungsprotkoll"
        required
      ></v-text-field>
      <v-checkbox
        v-model="proofOfInstallation"
        label="Montagenachweis vorhanden"
        :rules="[requiredRule]"
      ></v-checkbox>
      <v-btn
        color="primary"
        class="float-right"
        @click="saveHouseConnection(), validate()"
      >
        Speichern
      </v-btn>
      <br />
      <br />
    </v-form>
  </div>
</template>

<script>
// import { HTTP } from '@/components/_generic/Backend.js';
import axios from 'axios';
import debounce from 'debounce';
import _ from 'lodash';
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
// import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'HouseConnectionForm',
  components: {},
  props: {
    gAgs: {
      type: String,
      required: true
    },
    existingHouseConnection: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    addresses: undefined,
    toggles: [],
    activeItem: {},
    city: null,
    district: [],
    districtItems: [],
    city3: null,
    cities: [],
    city2: undefined,
    cities2: [],
    cityItems2: undefined,
    timer: null,
    loading: false,
    isUpdating: false,
    search: '',
    loadingCities: false,
    loadingDistricts: false,
    singleTubeColor: undefined,
    mfg: undefined,
    nvt: undefined,
    nvtSpliceCassette: undefined,
    pipeUnion: undefined,
    singlePipe: undefined,
    numberOfFibers: undefined,
    otdrProtocol: false,
    inspectionReport: undefined,
    proofOfInstallation: false,
    availableTubeColors: [
      {
        name: 'red',
        number: 1,
        description: 'rot',
        color: '#FE0000'
      },
      {
        name: 'green',
        number: 2,
        description: 'grün',
        color: '#00AA00'
      },
      {
        name: 'blue',
        number: 3,
        description: 'blau',
        color: '#0000FE'
      },
      {
        name: 'yellow',
        number: 4,
        description: 'gelb',
        color: '#FFFF00'
      },
      {
        name: 'white',
        number: 5,
        description: 'weiß',
        color: '#FFFFFF'
      },
      {
        name: 'gray',
        number: 6,
        description: 'grau',
        color: '#808080'
      },
      {
        name: 'brown',
        number: 7,
        description: 'braun',
        color: '#993400'
      },
      {
        name: 'violet',
        number: 8,
        description: 'violett',
        color: '#81007F'
      },
      {
        name: 'turquoise',
        number: 9,
        description: 'türkis',
        color: '#01FFFF'
      },
      {
        name: 'black',
        number: 10,
        description: 'schwarz',
        color: '#000000'
      },
      {
        name: 'orange',
        number: 11,
        description: 'orange',
        color: '#FF6600'
      },
      {
        name: 'pink',
        number: 12,
        description: 'rosa',
        color: '#FC02F7'
      },
      {
        name: 'red-black',
        number: 13,
        description: 'rot-schwarz',
        color: '#FE0000'
      },
      {
        name: 'green-black',
        number: 14,
        description: 'grün-schwarz',
        color: '#00AA00'
      },
      {
        name: 'blue-black',
        number: 15,
        description: 'blau-schwarz',
        color: '#0000FE'
      },
      {
        name: 'yellow-black',
        number: 16,
        description: 'gelb-schwarz',
        color: '#FFFF00'
      },
      {
        name: 'white-black',
        number: 17,
        description: 'weiß-schwarz',
        color: '#FFFFFF'
      },
      {
        name: 'gray-black',
        number: 18,
        description: 'grau-schwarz',
        color: '#808080'
      },
      {
        name: 'brown-black',
        number: 19,
        description: 'braun-schwarz',
        color: '#993400'
      },
      {
        name: 'violet-black',
        number: 20,
        description: 'violett-schwarz',
        color: '#81007F'
      },
      {
        name: 'turquoise-black',
        number: 21,
        description: 'türkis-schwarz',
        color: '#01FFFF'
      },
      {
        name: 'transparent-black',
        number: 22,
        description: 'transparent-schwarz',
        color: '#DDDDDD'
      },
      {
        name: 'orange-black',
        number: 23,
        description: 'orange-schwarz',
        color: '#FF6600'
      },
      {
        name: 'pink-black',
        number: 24,
        description: 'rosa-schwarz',
        color: '#FC02F7'
      }
    ]
    // selectAllDistricts: false
  }),
  computed: {
    // cityItems2() {
    //   return this.cities.length ? this.cities.city : [];
    // },
    districts() {
      if (this.districtItems.length) {
        const districtGroup = _.groupBy(this.districtItems, 'district');
        const districtArray = [];
        let districtName;
        // districtGroup = _.groupBy(this.districtItems, 'district');
        console.log(districtGroup);

        Object.keys(districtGroup).forEach((key) => {
          console.log(key, districtGroup[key][0].city);
          // console.log(ke)
          districtName = key.length ? key : districtGroup[key][0].city;
          districtArray.push(districtName);
        });
        return districtArray.sort();
      } else {
        return [];
      }
    },
    selectAllDistricts() {
      return this.district.length === this.districts.length;
    },
    selectSomeDistrict() {
      return this.district.length > 0 && !this.selectAllDistricts;
    },
    icon() {
      if (this.selectAllDistricts) return 'mdi-close-box';
      if (this.selectSomeDistrict) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    search(value) {
      if (!value) {
        return;
      }
      if (value.length >= 3) {
        debounce(this.makeSearch, 900)(value, this);
      }
    },
    city(value) {
      console.log(value.city);
      if (!value) {
        return;
      }
      if (value.city.length >= 3) {
        this.getDistricts(value.city);
      }
    },
    existingHouseConnection: {
      handler: function (newValue) {
        if (newValue) {
          this.mfg = newValue.mfg;
          this.nvt = newValue.nvt;
          this.nvtSpliceCassette = newValue.nvtSpliceCassette;
          this.pipeUnion = newValue.pipeUnion;
          this.singlePipe = newValue.singleTubeColor;
          this.numberOfFibers = newValue.numberOfFibers;
          this.otdrProtocol = newValue.otdrProtocol;
          this.inspectionReport = newValue.inspectionReport;
          this.proofOfInstallation = newValue.proofOfInstallation;
          this.singleTubeColor = newValue.singlePipe;
        } else {
          this.mfg = undefined;
          this.nvt = undefined;
          this.nvtSpliceCassette = undefined;
          this.pipeUnion = undefined;
          this.singlePipe = undefined;
          this.numberOfFibers = undefined;
          this.otdrProtocol = false;
          this.inspectionReport = undefined;
          this.proofOfInstallation = false;
          this.singleTubeColor = undefined;
        }
      },
      deep: true
    }
  },
  mounted() {
    // this.getAddresses();
    if (this.existingHouseConnection) {
      this.mfg = this.existingHouseConnection.mfg;
      this.nvt = this.existingHouseConnection.nvt;
      this.nvtSpliceCassette = this.existingHouseConnection.nvtSpliceCassette;
      this.pipeUnion = this.existingHouseConnection.pipeUnion;
      this.singlePipe = this.existingHouseConnection.singleTubeColor;
      this.numberOfFibers = this.existingHouseConnection.numberOfFibers;
      this.otdrProtocol = this.existingHouseConnection.otdrProtocol;
      this.inspectionReport = this.existingHouseConnection.inspectionReport;
      this.proofOfInstallation =
        this.existingHouseConnection.proofOfInstallation;
      this.singleTubeColor = this.existingHouseConnection.singlePipe;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    getAddresses() {
      axios
        .get('https://localhost:8800/addresses', {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
          }
        })
        .then((response) => (this.addresses = response.data))
        .catch((error) => console.log(error));
    },
    makeSearch: async (value, self) => {
      // Handle empty value
      if (!value) {
        self.cityItems2 = [];
        self.city = '';
      }
      // Items have already been requested
      if (self.loadingCities) {
        return;
      }

      self.loadingCities = true;

      axios
        .get(
          'http://localhost:3000/api/postcode-data?where[city][startsWith]=' +
            value,
          {
            headers: {
              Authorization: 'Basic YWRtaW46YWRtaW4=',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
          }
        )
        .then((response) => {
          self.cityItems2 = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (self.loadingCities = false));
    },
    getCities() {
      const _this = this;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        axios
          .get(
            'http://localhost:3000/api/postcode-data?where[city][startsWith]=' +
              _this.city,
            {
              headers: {
                Authorization: 'Basic YWRtaW46YWRtaW4=',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods':
                  'GET,PUT,POST,DELETE,PATCH,OPTIONS'
              }
            }
          )
          .then((response) => (_this.cities = response.data))
          .catch((error) => console.log(error));
      }, 800);
    },
    getCities2() {
      this.loading = true;
      axios
        .get(
          'http://localhost:3000/api/postcode-data?where[city][startsWith]=' +
            this.city2,
          {
            headers: {
              Authorization: 'Basic YWRtaW46YWRtaW4=',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
          }
        )
        .then(
          (response) => (this.cities2 = response.data),
          (this.loading = false)
        )
        .catch((error) => console.log(error));
    },
    getDistricts(city) {
      this.loading = true;
      axios
        .get(
          'http://localhost:3000/api/postcode-data?where[city][equals]=' + city,
          {
            headers: {
              Authorization: 'Basic YWRtaW46YWRtaW4=',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
          }
        )
        .then(
          (response) => (this.districtItems = response.data),
          (this.loading = false)
        )
        .catch((error) => console.log(error));
    },
    toggleActive(item) {
      if (this.activeItem[item.id]) {
        this.removeActiveItem(item);

        return;
      }

      this.addActiveItem(item);
    },
    saveHouseConnection: function () {
      if (
        this.mfg &&
        this.nvt &&
        this.nvtSpliceCassette &&
        this.pipeUnion &&
        this.singleTubeColor
      ) {
        HTTP.get('/past/geo/create-house-connection', {
          params: {
            gAgs: this.gAgs,
            mfg: this.mfg,
            nvt: this.nvt,
            nvtSpliceCassette: this.nvtSpliceCassette,
            pipeUnion: this.pipeUnion,
            singlePipe: this.singleTubeColor,
            numberOfFibers: this.numberOfFibers,
            otdrProtocol: this.otdrProtocol,
            inspectionReport: this.inspectionReport,
            proofOfInstallation: this.proofOfInstallation,
            lastEditor: this.$auth.user().userName
          }
        })
          .then((resp) => {
            this.$store.commit(
              'addNotification',
              new NotificationObject('success', resp.data.message)
            );
            this.$emit('close');
            this.$emit('update');
            this.mfg = null;
            this.nvt = null;
            this.nvtSpliceCassette = null;
            this.pipeUnion = null;
            this.singleTubeColor = null;
            this.numberOfFibers = null;
            this.otdrProtocol = false;
            this.inspectionReport = null;
            this.proofOfInstallation = false;
          })
          .catch((err) =>
            this.$store.commit(
              'addNotification',
              new NotificationObject('error', err.response.data)
            )
          )
          .finally(() => {});
      } else {
        this.$store.commit(
          'addNotification',
          new NotificationObject(
            'error',
            'Es wurden nicht alle Pflichtfelder ausgefüllt, bitte Eingabe überprüfen.'
          )
        );
      }
    },
    addActiveItem(item) {
      this.activeItem = Object.assign({}, { [item.id]: item });
    },
    removeActiveItem(item) {
      delete this.activeItem[item.id];
      this.activeItem = Object.assign({}, this.activeItem);
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllDistricts) {
          this.district = [];
        } else {
          this.district = this.districts.slice();
        }
      });
    },
    // validation rules
    numericRule: (v) => {
      if (!v) return 'Bitte geben Sie eine Nummer ein';
      if (!(v && /\d+/i).test(v)) return 'Bitte geben Sie eine Nummer ein';
      return true;
    },
    nvtSpliceCassetteRule: (v) => {
      if (!v) return 'Bitte geben Sie eine Nummer zwischen 1 und 100 ein';
      if (!(v && /\d+/i).test(v)) {
        return 'Bitte geben Sie eine Nummer zwischen 1 und 100 ein';
      }
      if (!(v && v >= 1)) {
        return 'Bitte geben Sie eine Nummer zwischen 1 und 100 ein';
      }
      if (!(v && v <= 100)) {
        return 'Bitte geben Sie eine Nummer zwischen 1 und 100 ein';
      }
      return true;
    },
    requiredRule: (v) => {
      return !!v || 'Bitte füllen Sie dieses Feld aus';
    }
  }
};
</script>
<style lang="scss" scoped>
.addr-item {
  display: block;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 3px 0;
}
.addr-details {
  display: none;
  transition: all 0.3s ease-in-out;
}
.addr-item:hover .addr-details {
  display: block;
}
.color-indicator {
  clear: both;
  margin-right: 10px;
  div {
    display: block;
    width: 12px;
    height: 24px;
    float: left;
  }
}
.left-indicator {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.middle-indicator {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  width: 8px !important;
}
.right-indicator {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
